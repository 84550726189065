import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';

import Glide from "@glidejs/glide"
import ToTop from "../components/to-top/to-top";

const sliderConfig= {
  gap: 20,
  perView: 1,
  startAt: 0,
  type: "slider"
};

const RecipeBook = () => {
  useEffect(() => {
    const sliders = document.querySelectorAll('.glide');

    sliders.forEach(item => {
        new Glide(item, sliderConfig).mount()
    })
  }, [])

  return (
    <Layout>
      <div className="details case-study hc-template rb-page">
        <SEO title=".Recipebook Project" />
        <div className="fixed-nav">
          <a className="home-link fixed-link rb-orange" href="/">
            <img className="arrow-left" alt="arrow-left" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
            <span>home</span>
          </a>
          <a className="next-link fixed-link" href="/gov-financial-app">
            <span>next</span>
            <img className="arrow-right" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
          </a>
        </div>
        <ToTop></ToTop>
        <section className="wrapper-container">
        <div className="row">
            <div className="col">
              <div className="vert-center">
              <h3>Helping to utilize recipes.</h3>
              <p>
              Conceptualized after a GV design sprint involving the cooking space, .Recipebook is a web application meant to complement recipe and excess food utilization.
              </p>
              <p><a rel="noopener noreferrer" href="https://www.figma.com/design/rYZ467LTF7w8O9wjnKyBUY/.Recipebook-for-Portfolio?node-id=1-2&t=fvipWlc5U2HyBH3T-1" title="Figma link" target="_blank">figma link</a></p>
              </div>
            </div>
            <div className="col hero-image">
              {/* <img alt="image of food" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739110076/pexels-ella-olsson-572949-1640777_1_k05zyi.png"></img> */}
            </div>
          </div>
        </section>
        <section className="wrapper-container image-container">
          {/* Hi-Fi Screens */}
          <div className='glide mb-20 mt-20'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-home_yeduam.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-home_yeduam.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-recipes_ikpprz.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-recipes_ikpprz.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                   <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-new_ytd0o9.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-new_ytd0o9.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-details_eqdj09.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-details_eqdj09.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-pantry_jd3suh.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-pantry_jd3suh.png" ></img></a>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396471/chevron-left_io2o5z.png"></img></button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396472/chevron-right_ejgxv3.png"></img></button>
              </div>
            </div>
        </section>

        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
              <h3 className="text-center">Discovery and empathy</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
              <p>
              Initial hypotheses were guided by a GV design sprint previously utilized to understand and better the cooking process.
              </p>
              <p>
              Competitor analysis was expanded upon while previous interviewees were willing to have a more focused and in-depth follow-up.
              </p>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                  Interviews
                </h3>
                <p>
                Interviews, along with competitor analysis, showed a need for simpler interactions - i.e., voice commands, streamlined tab and keyboard controls, and identifying key snippets. Features that could be designed and developed with the added benefit of improving accessibility.
                </p>
                <p>
                A majority of potential users also utilized a variety of platforms for saving recipes of different media types.
                </p>
              </div>
            </div>
          </div>
        </section>   
        <section className="wrapper-container image-container">
          {/* Affinity Map */}
          <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/affinity-map_j8m0fh.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395517/affinity-map_j8m0fh.png" ></img></a>
        </section>  
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                  Personas and empathy maps
                </h3>
                <p>
                Key aspects utilized included the diverse forms of media for cooking, ingredients utilization, and personal reasons for cooking and remembering recipes.
                </p>
              </div>
            </div>
          </div>
        </section>  
        <section className="wrapper-container image-container">
          {/* Persona and Empathy Map */}
          <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395518/persona-and-empathy-map_dlmgb8.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728395518/persona-and-empathy-map_dlmgb8.png" ></img></a>
        </section>  
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3 className="text-center">Ideate and implement</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
                <p>
                Diagramming the user flows and wireframing the recipe creation/importing process was chosen for red routes. This would allow for initial research regarding creating/importing a recipe and the underlying information available for subsequent screens.
                </p>
                <p>
                The recipe details page was also emphasized. Structured as a modal for quicker navigation and the preservation of the users state, the page was also minimal in content and included a search for scannability.
                </p>
                <p>
                Foundations for key features ideated such as a better keyboard experience on the details page and voice recognition were set in the form of steps with a “focused” design.
                </p>
              </div>
            </div>
          </div>
        </section> 
        <section className="wrapper-container image-container">
          {/*  Wireframes */}
          <div className='glide mb-20 mt-20'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727820/mock-wf-search_nezzhy.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727820/mock-wf-search_nezzhy.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-wf-create_urstav.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-wf-create_urstav.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                   <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-wf-details_rehgs2.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727819/mock-wf-details_rehgs2.png" ></img></a>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396471/chevron-left_io2o5z.png"></img></button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396472/chevron-right_ejgxv3.png"></img></button>
              </div>
            </div>
        </section>  
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                  Testing
                </h3>
                <p>
                Testing was done remotely with a medium-fidelity prototype.
                </p>
                <p>
                Important discoveries included the need for more context regarding AI or programmatic features, the willingness of users to input more complex details, and the preferred use of prompt-like interactions over conversational ones.
                </p>
              </div>
            </div>
          </div>
        </section>  

        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
              <h3 className="text-center">Visual identity</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
              <p>
              The visual identity of the application reflects that of the market and its potential competitors. With orange being utilized as the main color, a sans serif font utilized for content, and a serif font utilized when needing contrast.
              </p>
              </div>
            </div>
          </div>
        </section>  
        <section className="wrapper-container image-container">
          {/* Mood board */}
          <a className={`zoom-in asset-xlarge mb-20`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727820/mood-board-v2_cvqj2b.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742727820/mood-board-v2_cvqj2b.png" ></img></a>
        </section>
        <div className="project-bottom">
          <Link to="/gov-financial-app" title="To .gov redesign">
            <span>a .gov redesign</span>
            <img className="arrow-right-long" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/arrow-right-long-light_1_qchclz.png"></img>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default RecipeBook;